import axios from 'axios'
import { authHeader } from '../helpers'
import { authActions } from '../store'

const axiosClient = axios.create()
axiosClient.defaults.baseURL = `${process.env.REACT_APP_API_URL}/`
axiosClient.defaults.timeout = 50000 // 50 segundos
// axiosClient.defaults.withCredentials = true
asignarToken()

export function asignarToken() {
  axiosClient.defaults.headers = authHeader()
}

export function desasignarToken() {
  axiosClient.defaults.headers['Authorization'] = ''
}

export const api = axiosClient

export function getRequest(URL, dispatch) {
  return api
    .get(`${URL}`)
    .then((response) => response)
    .catch((error) => {
      // Error
      if (error.status == 401) {
        if (dispatch != null) dispatch(authActions.logout()) // No autorizado
      }
      if (error.response != null) {
        // dispatch(
        //   alertActions.error(
        //     { message: 'No se pueden cargar los datos', showAfterRedirect: true },
        //     'alert/error',
        //   ),
        // )
      } else {
        if (error.response) {
          // Devuelve 2xx
        } else if (error.request) {
          //   dispatch(
          //     alertActions.error(
          //       {
          //         message: 'El servidor no responde. Por favor, intente más tarde.',
          //         showAfterRedirect: true,
          //       },
          //       'alert/error',
          //     ),
          //   )
        } else {
          // Something happened in setting up the request that triggered an Error
          // console.log('Error 3')
        }
      }
    })
}

export function postRequest(URL, payload, dispatch) {
  return api
    .post(`${URL}`, payload)
    .then((response) => response)
    .catch((error) => {
      if (error.status == 401) {
        if (dispatch != null) dispatch(authActions.logout()) // No autorizado
      }
    })
}

export function patchRequest(URL, payload, dispatch) {
  return api
    .patch(`/${URL}`, payload)
    .then((response) => response)
    .catch((error) => {
      if (error.status == 401) {
        if (dispatch != null) dispatch(authActions.logout()) // No autorizado
      }
    })
}

export function deleteRequest(URL, dispatch) {
  return api
    .delete(`/${URL}`)
    .then((response) => response)
    .catch((error) => {
      if (error.status == 401) {
        if (dispatch != null) dispatch(authActions.logout()) // No autorizado
      }
    })
}
